<template>
  <div>
    <v-card class="pa-0 pa-sm-3 pa-md-3 pa-lg-3 pa-xl-3">
      <v-btn
        color="#0D47A1"
        block
        style="font-size:16px"
      >
        推送列表

        <v-btn
          style="position:absolute;right:0"
          text
          small
          color="#fff"
          @click="exportTable"
        >
          <v-icon
            left
            small
          >
            fa4 fa-download
          </v-icon>
          导出
        </v-btn>
      </v-btn>
      <v-row class="mt-2 mb-2">
        <v-col
          md="4"
          class="pb-0 pl-6"
        >
          <v-text-field
            v-model="park"
            class="fildName"
            label="接收单位"
            dense
            clearable
          />
        </v-col>
        <v-col
          md="4"
          class="pb-0 pl-1"
        >
          <DatePicker
            v-model="createData"
            format="yyyy-MM-dd"
            type="daterange"
            placement="bottom-end"
            placeholder="推送时间段"
            style="width: 100%;"
          />
        </v-col>
        <!-- <v-col md="3">
          <v-select
            v-model="stageStatus"
            clearable
            :items="stageStatuss"
            label="状态"
            item-text="name"
            item-value="code"
            dense
          />
        </v-col> -->
        <!-- <v-col
          md="3"
          class="pl-1"
        >
          <v-select
            v-model="lightStatus"
            clearable
            :items="lightStatuss"
            label="预警信息"
            item-text="name"
            item-value="code"
            dense
          />
        </v-col> -->
        <v-col
          md="4"
          class="pl-1 text-right"
        >
          <v-btn
            min-width="0"
            small
            color="blue mt-0"
            @click="getchild"
          >
            搜索
          </v-btn>
        </v-col>
      </v-row>
      <v-simple-table fixed-header>
        <thead>
          <tr :class="isXs ? 'titles' : ''">
            <th
              style="min-width:60px"
            >
              接收单位
            </th>
            <th
              style="min-width:80px"
            >
              推送时间
            </th>
            <th
              style="min-width:60px"
            >
              状态
            </th>
            <!-- <th>预警信息</th> -->
            <th
              v-permission="['ROLE_ADMIN','ROLE_SITEADMIN','ROLE_TZCJCFZRSHYJ']"
              width="60"
            >
              操作
            </th>
          </tr>
        </thead>
        <tbody
          v-for="(item,inx) in desserts"
          :key="inx"
        >
          <tr :class="isXs ? 'names' : ''">
            <td>{{ item.data.park }}</td>
            <td>
              {{ item.createdAt | dateformat }}
            </td>
            <!--  :style="'color:'+runColor(item.data.stageStatus)" -->
            <td>
              {{ item.data.stageStatus ? item.data.stageStatus:'待处理' }}
            </td>
            <!-- <td>
              <v-icon :color="timeColor(item)">
                mdi-lightbulb
              </v-icon> {{ item.data.answerTime ? item.data.answerTime:'---' }}
            </td> -->
            <td
              v-permission="['ROLE_ADMIN','ROLE_SITEADMIN','ROLE_TZCJCFZRSHYJ']"
            >
              <v-icon
                style="cursor:pointer"
                small
                class="mr-2"
                color="red"
                @click="deleteMobelBtn(item)"
              >
                fa4 fa-trash-o
              </v-icon>
            </td>
          </tr>
          <tr
            v-if="item.data.stageStatus==='中止' || item.data.stageStatus==='拒绝'"
            :key="inx"
          >
            <td colspan="5">
              <v-col
                cols="12"
                class="ma-0 pt-0 pb-0 mb-4 mt-4"
                style="color:red"
              >
                {{ item.data.stageStatus }}原因：{{ item.data.abortReason }}
              </v-col>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
  </div>
</template>

<script>
  import file from '../../../utils/file'
  export default {
    components: {
    },
    props: {
      formId: {
        type: String,
        default: null,
      },
      reportId: {
        type: String,
        default: null,
      },
      isdynamic: {
        type: String,
        default: null,
      },
      detailList: {
        type: Object,
        default: null,
      },
      isOrg: {
        type: String,
        default: null,
      },
    },
    data () {
      return {
        parkCode: [],
        childformId: '5afc5ec4-b2a0-49dc-b042-75d0079b396c',
        dynamicformId: 'cf45fb6b-8c8f-4e1e-b9f8-1b2b774fea2b',
        orgList: [],
        replaceFields: { title: 'name', value: 'id' },
        userGroupTree: [],
        userSelect: false,
        userGroup: '',
        desserts: [],
        parentOrgId: '',
        allGroupTree: [],
        parentList: {},
        stageStatus: '',
        createData: '',
        lightStatus: '',
        park: '',
        orgQX: false,
        searchParame: {},
        stageStatuss: [{ name: '待处理', code: '待处理' }, { name: '接收', code: '接收' }, { name: '拒绝', code: '拒绝' }, { name: '对接', code: '对接' }, { name: '中止', code: '中止' }, { name: '在谈', code: '在谈' }, { name: '签约', code: '签约' }, { name: '落地', code: '落地' }],
        lightStatuss: [{ name: '绿灯', code: '绿灯' }, { name: '黄灯', code: '黄灯' }, { name: '红灯', code: '红灯' }, { name: '灰灯', code: '灰灯' }],
        isXs: false,
      }
    },
    watch: {
      '$vuetify.breakpoint.name': {
        handler (name) {
          if (name === 'xs') {
            this.isXs = true
          } else if (name === 'sm') {
            this.isXs = false
          } else {
            this.isXs = false
          }
        },
        deep: true,
        // 代表在wacth里声明了这个方法之后立即先去执行handler方法
        immediate: true,
      },
    },
    async mounted () {
      this.orgQX = localStorage.orgQX === 'true' || localStorage.orgQX === true
      if (this.isdynamic) {
        this.userSelect = true
        this.$router.replace({ query: '' })
      }
      await this.getchild()
    },
    methods: {
      deleteMobelBtn (item) { // 删除按钮弹出弹框
        this.$swal({
          title: '你确定要删除此推送吗？',
          text: '删除后将无法恢复。',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then((result) => {
          if (result.value) {
            this.deleteProject(item)
          }
        })
      },
      // 更新是否推送
      async partpush (name) {
        const resp = await this.$axios.patch(`/reports/${this.detailList.id}`, {
          isPush: name,
        })
        console.log('resp', resp);
      },
      deleteProject (item) { // 删除项目
        this.$axios.delete('/reports/' + item.id)
          .then(async (res) => {
            this.desserts.splice(this.desserts.indexOf(item), 1)
            console.log('this.desserts', this.desserts, this.isOrg);
            // 市推送到 区县的推送表不影响项目的推送状态
            if (!this.desserts.length && this.isOrg !== '是') {
              // await this.partpush(null)
            }
            await this.$emit('pushInit')
            this.$swal({
              title: '删除成功!',
              icon: 'success',
              showConfirmButton: false,
              timer: 1500,
            })
          })
          .catch((error) => {
            console.log(error)
            this.$swal({
              title: '删除失败!',
              icon: 'error',
            })
          })
      },
      is_weixin () {
        var ua = window.navigator.userAgent.toLowerCase();
        if (ua.indexOf('micromessenger') !== -1) {
          console.log('微信浏览器');
          return true;
        } else {
          console.log('不是微信浏览器');
          return false;
        }
      },
      exportTable () {
        if (this.is_weixin()) {
          this.$swal({
            title: '点击右上角选择在浏览器中打开!',
            icon: 'warning',
            showConfirmButton: false,
            timer: 1500,
          })
        } else {
          const headers = { columns: 'name,park,parks,stageStatus,answerTime,lightStatus,industry,fillingUser,parentName,abortReason,isDocking' }
          delete this.searchParame.formId
          this.exportFile('reports/' + this.childformId + '/export', this.searchParame, headers)
        }
      },
      // 导出文件
      async exportFile (url, params, headers) {
        let data
        try {
          data = await this.$axios.get(url, {
            responseType: 'blob',
            params: params,
            headers,
          })
        } catch (e) {
          console.log(e)
        }
        setTimeout(() => {
        }, 1000);
        if (!data || !data.data || data.data.length < 200) {
          this.$swal({
            title: '导出失败，请稍后再试！',
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: '知道了',
          })
          return
        }
        file.downFile(data.data, '导出表格')
      },
      async getchild () {
        const paramsData = { formId: this.childformId, page: 0, size: 999, parentID: this.reportId }
        // if (!this.orgQX) {
        paramsData._from = 'public'
        // }
        if (this.stageStatus) {
          paramsData.stageStatus = this.stageStatus
        } else {
          delete paramsData.stageStatus
        }
        if (this.lightStatus) {
          paramsData.lightStatus = this.lightStatus
        } else {
          delete paramsData.lightStatus
        }
        if (this.park) {
          paramsData.park = this.park
        } else {
          delete paramsData.park
        }
        if (this.createData[0]) {
          paramsData._createdFrom = this.$moment(this.createData[0]).format('YYYY-MM-DD')
        } else {
          delete paramsData._createdFrom
        }
        if (this.createData[1]) {
          paramsData._createdTo = this.$moment(this.createData[1]).format('YYYY-MM-DD')
        } else {
          delete paramsData._createdTo
        }
        this.searchParame = paramsData
        const resp = await this.$axios.get('/reports/query', {
          params: paramsData,
        })
        this.desserts = resp.data.data.content
      },

      runColor (key) {
        let color = '#ACB4C7'
        switch (key) {
          case '在谈':
            color = '#20c954'
            break;
          case '落地':
            color = '#01579B'
            break;
          case '签约':
            color = '#FF9900'
            break;
          case null:
            color = '#ACB4C7'
            break;
          case '中止':
            color = 'red'
            break;
          case '对接':
            color = '#20c954'
            break;
          case '拒绝':
            color = 'red'
            break;
          case '接收':
            color = '#20c954'
            break;
          default:
            break;
        }
        return color
      },
      timeColor (item) {
        if (item.data.lightStatus === '绿灯') {
          return '#20c954'
        } else if (item.data.lightStatus === '红灯') {
          return 'red'
        } else if (item.data.lightStatus === '黄灯') {
          return '#FF9900'
        } else {
          return '#ACB4C7'
        }
      },
    },
  }
</script>
<style scoped lang='scss'>
::v-deep.v-dialog__content {
  justify-content: flex-end !important;
}
::v-deep .v-dialog {
  height: 86% !important;
}
::v-deep .fildName .v-text-field__details {
  display: none;
}
::v-deep .v-data-table__wrapper {
  max-height: 350px;
}
.names td{
font-size: 14px !important;
font-family: PingFang SC, PingFang SC-Medium;
color: #333333;
padding: 0 6px !important;
}
.titles th{
opacity: 0.6;
font-size: 13px !important;
font-family: PingFang SC, PingFang SC-Medium;
font-weight: 500;
color: #333333 !important;
padding:0 6px !important
}
</style>
